import React from "react";
import Layout from "../../components/Layout/Layout";
/******************* 
@Purpose : Used for admin email 
@Parameter : {}
@Author : INIC
******************/
function AdminEmail() {
  return (
    <Layout>
      <div>AdminEmail</div>
    </Layout>
  );
}

export default AdminEmail;
